import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import colors from '../config/colors';
import dimensions from '../config/dimensions';
import { fadeIn } from '../config/animations';

const UnStyledButton = ({ children, variant, color, icon, actionIcon, fluid, error, onClick, className, disabled, ref }) => {
    return (
        <div className={`${className} ${fluid ? 'fluid' : ''}`} ref={ref}>
            <button className={`${color} ${variant} ${disabled ? 'disabled' : ''}`} onClick={onClick} disabled={disabled}>
                <div>
                    {icon && <span className='material-icons'>{icon}</span>}
                    {actionIcon && <img src={actionIcon} alt='' />}
                    <span>{children}</span>
                </div>
            </button>
            {error && (
                <div>
                    <div>
                        <div>
                            <span />
                            <span>{error}</span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const extractColorName = (color, lightMode, variant) => {
    let colorName = color;
    if (lightMode) colorName += `${variant}${lightMode}`;
    else if (variant === 'Dark') colorName += 'Dark';
    return colorName;
};

const StyledButton = styled(UnStyledButton)`
    display: ${(props) => (props.fluid ? 'block' : 'inline-block')};
    position: relative;

    > button {
        display: flex;
        justify-content: ${(props) => (props.size === 'large' ? 'flex-start' : 'center')};
        align-items: center;
        width: 100%;
        min-height: ${(props) => dimensions.button.minHeight[props.size]};
        max-height: ${(props) => dimensions.button.maxHeight[props.size]};
        padding: ${(props) => dimensions.button.padding[props.size]};
        border: ${(props) => (props.variant === 'outline' ? `1px solid ${colors[props.color]}` : 'none')};
        background: ${(props) => (props.variant === 'outline' ? 'transparent' : colors[`${extractColorName(props.color, props.lightMode, 'Light')}`])};
        border-radius: var(--dimen-input-border-radius);
        color: ${colors.black};
        font-weight: ${(props) => (props.textStyle === 'bold' ? 700 : 600)};
        transition: all 200ms ease-in-out;
        box-shadow: ${(props) => (props.size === 'large' || props.size === 'medium' ? '0 0 10px 0 #4297A061' : 'none')};
        cursor: pointer;
        font-size: var(--input-size);
        position: relative;

        :focus {
            outline: none;
        }

        :hover {
            background: ${(props) => colors[`${extractColorName(props.color, props.lightMode, 'Dark')}`]};
            box-shadow: ${(props) => dimensions.button.boxShadow[props.size]};
        }

        &.primary {
            color: ${(props) => (['0', '1'].includes(props.lightMode) ? colors.primary : colors.white)};

            &:hover {
                color: var(--color-white);
            }
        }

        &.secondary {
            color: ${(props) => (props.lightMode ? colors.primary : colors.white)};

            &:hover {
                color: var(--color-white);
            }
        }

        &.red {
            color: #e46526;
        }

        &.light {
            color: ${(props) => colors[props.foregroundColor]};
        }

        &.outline {
            color: ${(props) => colors[props.color]};
            padding: 0.475rem 1.25rem;

            :hover {
                background: ${(props) => colors[`${props.color}OutlineLight`]};
                color: ${(props) => colors[props.color]};
            }
        }

        > div {
            display: flex;
            width: 100%;
            justify-content: ${(props) => (props.size === 'large' ? 'flex-start' : 'center')};
            align-items: center;

            > span {
                &.material-icons {
                    margin-right: 0.5rem;
                    font-size: 20px;
                }
            }

            > img {
                margin-right: 1rem;
                margin-bottom: ${(props) => (props.size === 'large' ? '1rem' : 0)};
                display: inline-block;
                max-height: ${(props) => (props.size === 'medium' ? '28px' : '40px')};
            }
        }

        &.disabled {
            :hover {
                background: ${(props) => (props.variant === 'outline' ? 'transparent' : colors[`${extractColorName(props.color, props.lightMode, 'Light')}`])};
            }

            ::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: var(--dimen-input-border-radius);
                background: rgba(255, 255, 255, 0.5);
            }
        }
    }

    > div {
        position: absolute;
        top: calc(100% + 15px);
        right: 0;
        width: 360px;
        z-index: 2;
        background: transparent;
        display: block;
        animation: ${fadeIn} 800ms forwards;

        > div {
            max-width: 100%;
            height: 100%;

            > div {
                background: #fff3f6;
                width: 100%;
                height: 100%;
                position: relative;
                padding: 1rem 1.75rem;
                border-radius: 0.5rem;
                box-shadow: 0 2px 4px 0 rgba(218, 0, 57, 0.15);
                position: relative;

                > span:first-child {
                    position: absolute;
                    top: -10px;
                    right: 10px;
                    width: 0;
                    height: 0;
                    border-left: 15px solid transparent;
                    border-right: 15px solid transparent;
                    border-bottom: 15px solid #fff3f6;
                }

                > span {
                    display: block;
                    color: #dd2c00;
                    font-style: italic;
                }
            }
        }
    }

    &.img-0 {
        > button {
            > div {
                > img {
                    margin-bottom: 0;
                }
            }
        }
    }
`;

const Button = (props) => <StyledButton {...props} />;

Button.propTypes = {
    variant: PropTypes.oneOf(['outline', '']),
    color: PropTypes.oneOf(['primary', 'secondary', 'light', 'red', '']),
    lightMode: PropTypes.oneOf(['0', '1', '2', '3', '']),
    foregroundColor: PropTypes.oneOf(['primary', 'secondary', '']),
    icon: PropTypes.string,
    size: PropTypes.oneOf(['normal', 'medium', 'large']),
    fluid: PropTypes.bool,
    actionIcon: PropTypes.any,
    iconRight: PropTypes.string,
    textStyle: PropTypes.oneOf(['bold', 'normal', 'semi-bold']),
    error: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string
};

Button.defaultProps = {
    variant: '',
    color: 'primary',
    lightMode: '',
    foregroundColor: 'secondary',
    icon: null,
    actionIcon: null,
    size: 'normal',
    fluid: false,
    iconRight: null,
    textStyle: 'bold',
    error: null,
    onClick: () => {},
    disabled: false,
    className: ''
};

export default Button;
