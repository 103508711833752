import styled from 'styled-components';

import colors from '../../config/colors';

export default styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${colors.container};
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
        display: flex;
        flex-direction: column;

        > main {
            padding: 3rem 3.5rem 2rem 3.5rem;
            position: relative;
            display: flex;
            align-items: center;
            color: ${colors.primary};

            > img {
                position: absolute;
                display: inline-block;
                height: 10rem;
            }

            > div {
                z-index: 2;
                margin: 0 8rem;
                text-align: center;

                h2 {
                    font-size: 2rem;
                    font-weight: 700;
                    padding: 0;
                }

                p {
                    font-size: 1rem;
                    font-weight: 500;
                    margin-bottom: 0.25rem;
                }
            }
        }

        > footer {
            display: flex;
            justify-content: flex-end;
            padding: 0 1rem 1rem 0;

            > img {
                height: 1.5rem;
            }
        }
    }
`;
