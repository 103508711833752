import styled from 'styled-components';

import colors from '../../../config/colors';
import dimensions from '../../../config/dimensions';
import { fontSizes } from '../../../config/sizes';

export default function (UnStyledButton) {
    return styled(UnStyledButton)`
        display: ${(props) => getDisplay(props)};
        width: ${(props) => getWidth(props)};
        min-height: ${(props) => getMinHeight(props)};
        max-height: ${(props) => getMaxHeight(props)};
        padding: ${(props) => getPadding(props)};
        border: ${(props) => getBorder(props)};
        background: ${(props) => getBackgroundColor(props)};
        border-radius: ${dimensions.button.borderRadius.rounded};
        box-shadow: ${(props) => getBoxShadow(props)};
        font-size: ${fontSizes.sm};
        color: ${(props) => getTextColor(props)};
        font-weight: 600;
        text-transform: ${(props) => getTextTransform(props)};
        cursor: ${(props) => getCursor(props)} !important;

        :hover {
            background: ${(props) => getBackgroundColor(props, true)};
            box-shadow: ${(props) => getBoxShadow(props, true)};
            color: ${(props) => getTextColor(props, true)};
        }

        > main {
            justify-content: ${(props) => getInnerDivJustifyContent(props)};

            > span {
                &.material-icons,
                &.material-icons-outlined {
                    margin-right: 0.5rem;
                    font-size: ${(props) => getIconFontSize(props)};
                }
            }
        }
    `;
}

function getDisplay({ fluid }) {
    return fluid ? 'block' : 'inline-block';
}

function getWidth({ fluid }) {
    return fluid ? '100%' : 'initial';
}

function getTextTransform({ size }) {
    return ['medium', 'large'].includes(size) ? 'uppercase' : 'initial';
}

function getInnerDivJustifyContent({ size }) {
    return size === 'large' ? 'flex-start' : 'center';
}

function getMinHeight({ size }) {
    return dimensions.button.minHeight[size];
}

function getMaxHeight({ size }) {
    return dimensions.button.maxHeight[size];
}

function getPadding({ size }) {
    return dimensions.button.padding[size];
}

function getBorder({ variant, color, disabled }) {
    return variant === 'outline' ? (disabled ? `1px solid ${colors.disabled}` : `1px solid ${colors[color]}`) : 'none';
}

function getBackgroundColor(props, hovered = false) {
    const { variant, ...otherProps } = props;

    return { outline: getOutlineBackgroundColor, filled: getFilledBackgroundColor }[variant](otherProps, hovered);
}

function getOutlineBackgroundColor({ color, disabled }, hovered) {
    return hovered && !disabled ? colors[`${color}OutlineLight`] : 'transparent';
}

function getFilledBackgroundColor({ color, lightMode, disabled }, hovered) {
    return disabled ? colors.disabled : hovered ? colors[getFilledBackgroundColorNameHovered(color, lightMode)] : colors[getFilledBackgroundColorNameEnabled(color, lightMode)];
}

function getFilledBackgroundColorNameHovered(color, lightMode) {
    return isValidLightMode(lightMode) ? color.concat('Dark', lightMode) : color.concat('Dark');
}

function getFilledBackgroundColorNameEnabled(color, lightMode) {
    return isValidLightMode(lightMode) ? color.concat('Light', lightMode) : color;
}

function getTextColor(props, hovered = false) {
    const { variant, ...otherProps } = props;

    return { outline: getOutlineTextColor, filled: getFilledTextColor }[variant](otherProps, hovered);
}

function getOutlineTextColor({ color, disabled }) {
    return disabled ? colors.disabled : colors[color];
}

function getFilledTextColor({ color, lightMode, disabled }, hovered) {
    return disabled ? colors.white : hovered ? getFilledTextColorCodeHovered(color, lightMode) : getFilledTextColorCodeEnabled(color, lightMode);
}

function getFilledTextColorCodeEnabled(color, lightMode) {
    return isPrimaryColorAsEnabled(color, lightMode) ? colors.primary : isSpecialColor(color) ? getFilledTextColorCodeForSpecialColor(color) : colors.white;
}

function getFilledTextColorCodeHovered(color) {
    return isSpecialColor(color) ? getFilledTextColorCodeForSpecialColor(color) : colors.white;
}

function getFilledTextColorCodeForSpecialColor(color) {
    return { light: colors.secondary, red: colors.error }[color];
}

function isPrimaryColorAsEnabled(color, lightMode) {
    return (color === 'primary' && ['0', '1'].includes(lightMode)) || (isValidLightMode(lightMode) && color === 'secondary');
}

function isValidLightMode(lightMode) {
    return lightMode !== 'none';
}

function isSpecialColor(color) {
    return ['red', 'light'].includes(color);
}

function getBoxShadow(props, hovered = false) {
    const { size, disabled } = props;

    return hovered && !disabled ? dimensions.button.boxShadow[size] : ['medium', 'large'].includes(size) ? '0 0 10px 0 #4297A061' : 'none';
}

function getCursor({ disabled }) {
    return disabled ? 'not-allowed' : 'pointer';
}

function getIconFontSize({ iconSize }) {
    return { sm: '20px', md: '26px', lg: '32px' }[iconSize];
}
