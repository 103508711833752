import { authHeaders, endpointV2 } from '../../api';
import storage from '../../utils/storage';

const getBaseUrl = () => {
    return `/organizations/${storage.getOrganizationUUID()}/subscription`;
};

export const createCheckoutSingleFolders = (params) => endpointV2.post(`${getBaseUrl()}/session-single-folders`, params, { headers: authHeaders() });

export const getActiveSubscription = () => endpointV2.get(`${getBaseUrl()}/active_subscription`, { headers: authHeaders() });
